import { useNavigate } from 'react-router-dom';

import { Text, SubTitle, Back } from './page-news.styled';
import { news } from './news';

function NewsDetail(props: { id: string }) {
  const navigate = useNavigate();
  const { id } = props;
  const isDetail = id && news[id];
  if (!isDetail) {
    return <p>Новость не найдена</p>;
  }
  const detail = news[id];
  const back = (event: any) => {
    event.preventDefault();
    navigate(-1);
  };

  return (
    <>
      <SubTitle>{detail.title}</SubTitle>
      <Text>{detail.text}</Text>
      <Back onClick={back}>вернуться</Back>
    </>
  );
}

export default NewsDetail;
