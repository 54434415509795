import styled from 'styled-components';

export const Title = styled.h1`
  font-size: 34px;
  font-weight: bold;
  margin: 0 0 34px;
`;

export const News = styled.div`
  padding: 10px 0 30px;
`;
export const NewsItems = styled.div``;

export const NewsItem = styled.div`
  margin: 0 0 0px;
  font-size: 18px;
  padding: 15px 0;
  border-bottom: 1px solid #ddd;
  a {
    color: #13c671;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
`;

export const SubTitle = styled.div`
  margin: 0 0 26px;
  font-weight: bold;
  font-size: 18px;
  line-height: 1;
`;

export const Text = styled.div`
  font-size: 16px;
  line-height: 1.3;
  p {
    margin: 0 0 18px;
  }
  a {
    color: #13c671;
  }
`;

export const Back = styled.a`
  display: block;
  font-size: 16px;
  margin: 15px 0 0;
  padding: 15px 0;
  text-decoration: underline;
  &:hover{
    text-decoration: none;
    cursor: pointer;
  }
`;
