import styled, { css } from 'styled-components';

export const Place = styled.div`
  max-width: 477px;
  margin: 0 -15px;
  @media screen and (max-width: 991px) {
    max-width: none;
  }
  ${(props) =>
    props.center &&
    css`
      max-width: none;
      width: 100%;
      margin: 0;
    `}
  ${(props) =>
    props.menu &&
    css`
      margin: 0;
      display: none;
      position: absolute;
      bottom: 19px;
      left: 20px;
      right: 20px;
      @media screen and (max-width: 991px) {
        display: block;
      }
    `}
`;
export const Items = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  a {
    line-height: 1;
    display: block;
    margin: 0 15px 30px;
    transition: 0.2s all ease-in;
  }
  a:last-child {
    margin-bottom: 0;
  }
  a:hover {
    opacity: 0.75;
  }

  @media screen and (max-width: 991px) {
    justify-content: flex-start;
  }

  ${(props) =>
    props.menu &&
    css`
      flex-wrap: nowrap;
      a {
        margin-left: 0px;
        margin-bottom: 1px;
      }
      img {
        max-width: 100%;
        height: auto;
      }
    `}

  ${(props) =>
    props.center &&
    css`
      justify-content: center !important;
    `}
`;
