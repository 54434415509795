import styled from 'styled-components';

export const Title = styled.h1`
  font-size: 34px;
  font-weight: bold;
  margin: 0 0 34px;
`;

export const Text = styled.div`
  font-size: 18px;
  line-height: normal;
  h2 {
    font-size: 24px;
    margin-top: 2rem;
  }
`;
