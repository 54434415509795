import { Link } from 'components/elements/link';

function Detail() {
  const link = 'https://bstr.onelink.me/l7cc/657hmubv';
  return (
    <>
      <p>
        Скачать приложение в <Link href={link}>Google Play или App Store</Link>
      </p>

      <p>
        <a href="https://transport.mybstr.ru/?utm_source=media&utm_medium=article&utm_campaign=pressrelease">
          В приложении BSTR
        </a>{' '}
        есть функционал, как для пешеходов, так и для автолюбителей. Уже доступна оплата общественного транспорта через
        разовый qr-код, пополнение подорожника, оплата городской парковки и моек самообслуживания. В ближайшее время
        планируется добавить оплату пригородных поездов - электричек, оплату коммерческих автостоянок и многое другое.
      </p>

      <p>В приложении отсутствует комиссия и цена за проезд такая же, как в официальных кассах. </p>

      <p>
        Больше не нужно стоять очереди в кассу, чтобы пополнить подорожник. Один раз привязав банковскую карту, можно за
        считанные секунды пополнить баланс. А в связи с увеличением платных парковок в центре Петербурга, приложение
        BSTR станет удобным способом оплаты городских парковок, не выходя из машины. В приложении BSTR нет дополнительных платежей, и комиссия за оплату парковки
        отсутствует. Приложение также умеет запоминать предыдущие парковочные сессии, напоминать об окончании парковки и
        сделает поездку в центр города комфортнее.
      </p>

      <p>
        <a href="https://transport.mybstr.ru/?utm_source=media&utm_medium=article&utm_campaign=pressrelease">
          Команда BSTR
        </a>{' '}
        развивает транспортные услуги в Петербурге, через объединение их на единой платформе. Они стремятся создать
        универсальный транспортный сервис для жителей Петербурга.
      </p>

      <p>
        Скачать приложение в <Link href={link}>Google Play или App Store</Link>
      </p>
    </>
  );
}

export default Detail;
