import List from 'components/elements/list';
//import { Place } from './container.styled';

function Block2View(props: any) {
  const { title, desc, items } = props;

  return (
    <div className="two-columns">
      <div className="block2__left two-columns__left">
        <div>
          <div className="title" dangerouslySetInnerHTML={{ __html: title }}></div>
          <div className="text" dangerouslySetInnerHTML={{ __html: desc }}></div>
        </div>
      </div>
      <div className="block2__right two-columns__right">
        <List cl="list1" items={items} />
      </div>
    </div>
  );
}

export default Block2View;
