import MailForm from './forms/mail-form';
import Container from 'components/elements/container';
import './blockmail.css';

function BlockMail() {
  return (
    <div className="blockmail">
      <Container>
        <div className="blockmail__flex">
          <div className="blockmail__left">
            <img src="/images/blockmail/img1.png" alt="" />
          </div>
          <div className="blockmail__right">
            <div className="title">Идеи, предложения?</div>
            <div className="subtitle">Напишите и мы обязательно ответим!</div>
            <MailForm />
          </div>
        </div>
      </Container>
    </div>
  );
}

export default BlockMail;
