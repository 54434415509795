import News20230206 from './details/news-2023-02-06';

export type INews = {
  text: any;
  title: string;
};

export const news: Record<string, INews> = {
  '20230206': {
    text: <News20230206 />,
    title: 'Теперь петербуржцы могут пополнять подорожник, оплачивать парковку через мобильное приложение!',
  },
};
