import Block1 from 'pages/main/block1/block1';
import Block2 from 'pages/main/block2/block2';
import Block3 from 'pages/main/block3/block3';
import Block4 from 'pages/main/block4/block4';
//import BlockFaq from 'pages/main/blockfaq/blockfaq';
import BlockMail from 'pages/main/blockmail/blockmail';

function PageMain() {
  return (
    <>
      <Block1 />
      <Block2 />
      <Block3 />
      <Block4 />
      {/*<BlockFaq />*/}
      <BlockMail />
    </>
  );
}

export default PageMain;
