import { useState } from 'react';
import { useLocation } from 'react-router-dom';

import AppLinks from 'components/applinks';
import Logo from './elements/logo';
import { Humburger, Close, MenuPlace, MenuItem } from './menu.styled';

const items = [
  { title: 'О проекте', link: '/#about' },
  { title: 'Преимущества', link: '/#avd' },
  { title: 'Как это работает', link: '/#hiw' },
  //{ title: 'Вопрос-ответ', link: '/#faq' },
  { title: 'Новости', link: '/news' },
  { title: 'Скачать', link: '/#apps', aClass: 'download' },
];

function Menu() {
  const [open, setOpen] = useState(false);
  const location = useLocation();
  const path = location.pathname;
  const isNews = path.indexOf('news') !== -1;
  const isRZD = path.indexOf('rzd') !== -1;
  //console.log('🚀 ~ Menu ~ path', path);

  const toggleMenu = () => {
    setOpen(!open);
    if (open) {
      window.jQuery('body').addClass('menu-active');
    } else {
      window.jQuery('body').removeClass('menu-active');
    }
  };
  const closeMenu = () => {
    setOpen(false);
    window.jQuery('body').removeClass('menu-active');
  };

  const linkClick = (e: any) => {
    var anchor = window.jQuery(e.target);
    let link = anchor.attr('href');

    if (isNews || isRZD) {
      return true;
    }

    closeMenu();
    window.slideTo(link);
  };

  const menuItems = items.map((el, idx) => {
    const notMain = el.link.indexOf('#') === -1;
    if (notMain) {
      return (
        <MenuItem className={'menu__item' + (notMain && isNews ? ' menu__item--active' : '')} key={idx}>
          {/*<Link to={el.link}>{el.title}</Link>*/}
          <a className={'slide-link' + (el.aClass ? ' ' + el.aClass : '')} href={el.link} onClick={linkClick}>
            {el.title}
          </a>
        </MenuItem>
      );
    }
    return (
      <MenuItem className={'menu__item' + (!idx && !isNews ? ' menu__item--active' : '')} key={idx}>
        <a className={'slide-link' + (el.aClass ? ' ' + el.aClass : '')} href={el.link} onClick={linkClick}>
          {el.title}
        </a>
      </MenuItem>
    );
  });

  return (
    <>
      <Humburger type="button" className={'humburger' + (open ? ' active' : '')} onClick={toggleMenu}>
        <img src="/images/menu.svg" alt="=" width={24} />
      </Humburger>
      <MenuPlace className={'menu' + (open ? ' active' : '')}>
        <Logo className="menu__logo" />
        <ul>{menuItems}</ul>
        <AppLinks menu />
        <Close type="button" className="menu__close" onClick={closeMenu}>
          <img src="/images/close.svg" alt="X" height="20" />
        </Close>
      </MenuPlace>
    </>
  );
}

export default Menu;
