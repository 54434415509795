import Block3View from './block3view';
import './block3.css';

function Block3() {
  const items1 = [
    { icon: '/images/block3/img2.svg', title: 'Добавьте карту “Подорожник”' },
    { icon: '/images/block3/img3.svg', title: 'Пополните баланс' },
    { icon: '/images/block3/img4.svg', title: 'Активируйте баланс при помощи валидатора' },
  ];
  const items2 = [
    { icon: '/images/block3/img2a.svg', title: 'Введите почту' },
    { icon: '/images/block3/img3a.svg', title: 'Оплатите билет картой' },
    { icon: '/images/block3/img4a.svg', title: 'Используйте QR в метро/наземном транспорте' },
  ];

  return (
    <div className="block block3" id="hiw">
      <div className="title">Как это работает?</div>
      <Block3View items1={items1} items2={items2} />
    </div>
  );
}

export default Block3;
