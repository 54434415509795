import { useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';

import { init } from 'common';
import Layout from 'components/layout';
import PageMain from 'pages/main/page-main';
import PageNews from 'pages/news/page-news';
import PageRZD from 'pages/rzd/page-rzd';


function App() {
  useEffect(() => {
    init();
  }, []);

  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route index element={<PageMain />} />
        <Route path="rzd" element={<PageRZD />} />
        <Route path="news" element={<PageNews />} />
        <Route path="news/:id" element={<PageNews />} />
        <Route path="*" element={<PageMain />} />
      </Route>
    </Routes>
  );
}

export default App;
