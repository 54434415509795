import Container from 'components/elements/container';
import Block4View from './block4view';
import './block4.css';

function Block4() {
  const items = [
    { title: 'Пополнение подорожника', link: 'https://transport.mybstr.ru' },
    { title: 'Оплата <br />городских парковок', link: 'https://mybstr.ru' },
    { title: 'Оплата моек самообслуживания', link: 'https://carwash.mybstr.ru' },
    //{ title: 'Покупка разового билета на транспорт', link: 'https://transport.mybstr.ru' },
  ];
  return (
    <div className="block block4">
      <Container>
        <div className="title">
          <b>Другие</b> наши услуги
        </div>
        <Block4View items={items} />
      </Container>
    </div>
  );
}

export default Block4;
