import Container from 'components/elements/container';
import { Title, Text } from './page-rzd.styled';

function PageRZD() {
  return (
    <Container>
      <Title>Правила перевозки пассажиров</Title>
      <Text>
        <h2>Перевозка пассажиров</h2>

        <p>
          1.            Для проезда в поезде пригородного сообщения пассажир обязан приобрести проездной документ
          (билет) для разовой поездки «Туда» или «Туда и обратно», либо абонементный билет установленной формы.
          Проездной документ (билет), приобретенный пассажиром на поезд пригородного сообщения, сохраняется им в течение
          всего пути следования поезда и до момента выхода через пункт контроля проездных документов (билетов) (при
          наличии указанного пункта) на железнодорожной станции, железнодорожном вокзале и остановочных пунктах.
        </p>

        <p>
          2.            Физическое лицо, не предъявившее уполномоченному перевозчиком лицу при проверке наличия и
          действительности проездных документов (билетов) в поезде или при выходе через пункт контроля действительного
          проездного документа (билета) либо документов, подтверждающих право на бесплатный или льготный проезд,
          является безбилетным.
        </p>

        <p>3.            При проезде в поезде пригородного сообщения пассажир имеет право:</p>

        <p>- провозить бесплатно детей в возрасте не старше 7 лет;</p>

        <p>– провозить с собой ручную кладь в установленном порядке;</p>

        <p>
          – получать полную стоимость проезда в случае незапланированного перерыва в движении поездов более чем на час.
        </p>

        <p>
          4.            Проездной документ (билет), оформленный для разовой поездки в одном направлении на поезде
          пригородного сообщения без указания мест, действителен на одну поездку в течение календарных суток, указанных
          в билете, и одного часа следующих календарных суток. Проездной документ (билет), оформленный в направлении
          туда и обратно, действителен, кроме одной поездки в направлении туда, на одну поездку в направлении обратно в
          течение суток, указанных в билете, и следующих календарных суток, не считая дня оформления билета, а также
          общевыходных и праздничных дней. Если срок действия проездного документа (билета) на поезд пригородного
          сообщения заканчивается в момент нахождения пассажира в поезде, проездной документ (билет) является
          действительным до прибытия пассажира на станцию (остановочный пункт) его назначения.
        </p>

        <p>
          5.            Возврат стоимости неиспользованного пассажиром билета для разовой поездки на поезде пригородного
          сообщения не производится. Исключение составляют случаи незапланированного перерыва в движении таких поездов
          пригородного сообщения более чем на час.
        </p>

        <p>
          Возврат стоимости неиспользованного пассажиром билета для разовой поездки на поезде пригородного сообщения
          производится только в часы незапланированного перерыва в движении таких поездов на станции отправления
          пассажиров.
        </p>

        <p>
          6.            При прерывании по причинам, зависящим от пассажира, поездки в поезде пригородного сообщения по
          проездному документу (билету) для разовой поездки возврат стоимости проезда за непроследованное расстояние не
          осуществляется.
        </p>

        <h2>Перевозка ручной клади</h2>

        <p>
          7.            Пассажир имеет право бесплатного провоза с собой на один проездной документ (билет) кроме мелких
          вещей ручной клади весом не более 36 кг, размер которой в сумме трёх измерений не превышает 180 см.
        </p>

        <p>
          Пассажир имеет право дополнительно к установленной норме провезти до 50 кг ручной клади за отдельную плату.
          Разрешается в тамбуре пригородного поезда провозить за плату дополнительно к установленной норме не более
          одного велосипеда в неразобранном виде.
        </p>

        <p>
          8.            Допускается перевозка в качестве ручной клади за дополнительную плату электронной, бытовой,
          видео- и аудиотехники, размер которой в сумме трёх измерений превышает 180 см, не более одного предмета на
          проездной документ (билет). За каждый перевозимый указанный предмет, независимо от его веса, взимается плата
          по тарифу багажа весом в 30 кг.
        </p>

        <p>
          Не допускается размещение ручной клади в проходах между сиденьями, в коридорах, тамбурах вагонов, на местах,
          предназначенных для пассажиров.
        </p>

        <p>
          9.            За перевозку в поездах пригородного сообщения мелких домашних (комнатных) животных, собак и птиц
          взимается плата.
        </p>

        <p>
          10.          Перевозка собак крупных пород, в том числе служебных, производится в намордниках и с поводком в
          тамбуре поезда пригородного сообщения (не более двух собак на один вагон) – под наблюдением их владельцев или
          сопровождающих с оплатой стоимости их провоза.
        </p>

        <p>
          11.          Собак-проводников инвалиды провозят с собой в вагонах всех типов. Плата за перевозку
          собак-проводников не взимается и перевозочные документы не оформляются. Собака-проводник должна иметь ошейник
          и намордник и находиться у ног пассажира, которого она сопровождает.
        </p>

        <h2>Проверка наличия проездных документов</h2>

        <p>
          12.          Проверка наличия у пассажиров проездных документов (билетов) на поезда пригородного сообщения
          осуществляется в пути следования поезда, перед посадкой и после поездки при проходе через пункт контроля на
          станциях (вокзалах) и остановочных пунктах.
        </p>

        <p>
          13.          Проверку наличия у пассажиров проездных документов (билетов) в пригородных поездах осуществляют
          кассиры-контролёры и другие контролирующие лица установленным порядком.
        </p>

        <p>
          14.          Физическое лицо, не предъявившее уполномоченному перевозчиком лицу при проверке наличия и
          действительности проездных документов (билетов) в поезде или при выходе через пункт контроля действительного
          проездного документа (билета) либо документов, подтверждающих право на бесплатный или льготный проезд,
          является безбилетным.
        </p>

        <p>
          Безбилетное физическое лицо, обнаруженное в поезде пригородного сообщения, обязано приобрести проездной
          документ (билет) с внесением платы за проезд исходя из действующих тарифов, в соответствии с категорией поезда
          и маршрутом следования, указанным безбилетным физическим лицом, но не менее чем за расстояние от последнего
          пункта отправления, в том числе последней железнодорожной станции отправления, где совершал остановку этот
          поезд, до указанного безбилетным физическим лицом пункта назначения, в том числе железнодорожной станции
          назначения, а также сбора за оказание услуги по оформлению проездного документа (билета) в поезде с
          оформлением соответствующего документа.
        </p>

        <p>
          В случае обнаружения безбилетного физического лица при выходе через пункт контроля плата за проезд взимается
          за расстояние от указанного им пункта отправления, в том числе железнодорожной станции отправления, до пункта
          назначения, в том числе железнодорожной станции назначения, где расположен пункт контроля, а также сбор за
          оказание услуги по оформлению проездного документа (билета).
        </p>

        <p>
          15.          При отказе безбилетного физического лица от приобретения проездного документа (билета), а также
          сбора за оказание услуги по оформлению проездного документа (билета), уполномоченным перевозчиком лицом на
          основании предоставленного безбилетным физическим лицом документа, удостоверяющего его личность, составляется
          акт в двух экземплярах. Один экземпляр остается у уполномоченного перевозчиком лица, второй выдается
          безбилетному физическому лицу.
        </p>

        <p>
          После составления акта с безбилетного физического лица взыскивается штраф в размере, установленном статьей
          110.1 Федерального закона от 10 января 2003 г. N 18-ФЗ &quot;Устав железнодорожного транспорта Российской
          Федерации&quot;, и безбилетное физическое лицо удаляется из поезда работником перевозчика, на которого в
          установленном порядке возложено осуществление контроля за наличием у пассажиров проездных документов
          (билетов), на ближайшей железнодорожной станции или остановочном пункте.
        </p>

        <p>Право оформления штрафа в установленном порядке возложено на контролёров-ревизоров!</p>

        <p>
          16.          При возникновении сомнения относительно возраста детей, провозимых бесплатно, кассиры-контролёры
          вправе потребовать предъявления соответствующих документов, подтверждающих возраст ребёнка (например,
          свидетельство о рождении, запись в паспорте родителей и т.п.), а при наличии льготного проездного документа
          (билета), оформленного школьнику старше 14 лет – справки из общеобразовательного учебного заведения.
        </p>

        <h2>В пригородных поездах запрещается</h2>

        <p>
          17.          Перевозить вещи (предметы), которые могут повредить или загрязнить вагон и вещи других
          пассажиров, а также зловонные, огнеопасные, отравляющие, легковоспламеняющиеся, взрывчатые и другие опасные
          вещества.
        </p>

        <p>
          18.          Огнестрельное оружие при перевозке в качестве ручной клади должно находится в чехле, кобуре или
          специальном футляре в разряженном состоянии отдельно от патронов.
        </p>

        <p>19.          Повреждать внутривагонное оборудование, стекла локомотивов и вагонов.</p>

        <p>20.          Нарушать технику безопасности при проезде, посадке и высадке из вагона.</p>

        <p>21.          Распивать спиртные напитки.</p>

        <p>22.          Курить в вагонах и тамбурах.</p>

        <h2>Пассажир может быть удалён из поезда</h2>

        <p>
          а) работниками органов внутренних дел – если он при посадке в поезд или в пути следования нарушает правила
          проезда, общественный порядок и мешает спокойствию других пассажиров;
        </p>

        <p>
          б) медицинскими работниками - в случае болезни пассажира, препятствующей возможности его дальнейшей поездки
          или угрожающей здоровью других пассажиров. Пассажир удаляется из поезда лишь на той железнодорожной станции,
          где имеются необходимые лечебные учреждения;
        </p>

        <p>
          в) работниками перевозчика, на которых в установленном порядке возложено осуществление контроля за наличием у
          пассажиров проездных документов (билетов) – если пассажир проезжает без проездного документа (билета) или по
          недействительному проездному документу (билету) и отказывается оплатить стоимость проезда в порядке,
          определяемом настоящими Правилами;
        </p>

        <p>г) в иных случаях, установленных законодательством Российской Федерации.</p>

        <p>&nbsp;</p>

        <p>
          <a target="_blank" rel="noreferrer" href="http://www.consultant.ru/document/cons_doc_LAW_40444/">
            http://www.consultant.ru/document/cons_doc_LAW_40444/
          </a>{' '}
          &nbsp; (Федеральный закон от 10.01.2003 N 18-ФЗ &quot;Устав железнодорожного транспорта Российской
          Федерации&quot;)
        </p>

        <p>
          <a target="_blank" rel="noreferrer" href="http://www.consultant.ru/document/cons_doc_LAW_40443/">
            http://www.consultant.ru/document/cons_doc_LAW_40443/
          </a>{' '}
          &nbsp; (Федеральный закон от 10.01.2003 N 17-ФЗ &quot;О железнодорожном транспорте в Российской
          Федерации&quot;)
        </p>

        <p>
          <a target="_blank" rel="noreferrer" href="http://www.consultant.ru/document/cons_doc_LAW_305/">
            http://www.consultant.ru/document/cons_doc_LAW_305/
          </a>{' '}
          &nbsp; (Закон РФ от 07.02.1992 N 2300-1 &quot;О защите прав потребителей&quot;)
        </p>

        <p>
          <a target="_blank" rel="noreferrer" href="http://www.consultant.ru/document/cons_doc_LAW_385630/92d969e26a4326c5d02fa79b8f9cf4994ee5633b/">
            http://www.consultant.ru/document/cons_doc_LAW_385630/92d969e26a4326c5d02fa79b8f9cf4994ee5633b/
          </a>{' '}
          &nbsp; (Постановление Правительства РФ от 27.05.2021 N 810 &quot;Об утверждении Правил оказания услуг по
          перевозкам на железнодорожном транспорте пассажиров, а также грузов, багажа и грузобагажа для личных,
          семейных, домашних и иных нужд, не связанных с осуществлением предпринимательской деятельности&quot;)
        </p>

        <p>
          <a target="_blank" rel="noreferrer" href="http://www.consultant.ru/document/cons_doc_LAW_166504/">
            http://www.consultant.ru/document/cons_doc_LAW_166504/
          </a>{' '}
          &nbsp; (Приказ Минтранса России от 19.12.2013 N 473 &quot;Об утверждении Правил перевозок пассажиров, багажа,
          грузобагажа железнодорожным транспортом&quot; (в ред. Приказов Минтранса России от 27.08.2015 3 267, от
          21.07.2016 № 202, от 30.11.2016 № 367, от 18.09.2018 № 334, от 14.11.2018 № 410 –{' '}
          <b>данный документ действует до 31.08.2023г.</b>).
        </p>

        <p>
          <a target="_blank" rel="noreferrer" href="http://www.consultant.ru/document/cons_doc_LAW_430073/7e36c5099806017fdb85bb8034ac63a7af1ac9a9/#dst100019">
            http://www.consultant.ru/document/cons_doc_LAW_430073/7e36c5099806017fdb85bb8034ac63a7af1ac9a9/#dst100019
          </a>{' '}
          &nbsp; (Приказ Минтранса России от 05.09.2022 N 352 &quot;Об утверждении Правил перевозок пассажиров, багажа,
          грузобагажа железнодорожным транспортом&quot; (Зарегистрировано в Минюсте России 27.10.2022 N 70720) –{' '}
          <b>действует с 01.09.2023г.</b>)
        </p>
      </Text>
    </Container>
  );
}

export default PageRZD;
