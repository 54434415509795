import styled from 'styled-components';

export const Place = styled.div`
  width: 1397px;
  margin: 0 auto;
  @media screen and (max-width: 1469px) {
    width: 970px;
  }
  @media screen and (max-width: 991px) {
    width: auto;
    padding: 0 20px;
  }
`;
