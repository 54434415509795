import Container from 'components/elements/container';

function Block3View(props: any) {
  const { items1, items2 } = props;
  const getItems = (items: any) => {
    return items.map((el: any, idx: any) => {
      return (
        <div className="block3__item" key={idx}>
          <div className="block3__item-img">
            <img src={el.icon} alt="" />
          </div>
          <div className="block3__item-title">{el.title}</div>
        </div>
      );
    });
  };
  const itemsEl1 = getItems(items1);
  const itemsEl2 = getItems(items2);

  return (
    <>
      <div className="block3__1">
        <div className="block3__title">
          <Container>
            Пополнение <br />
            подорожника:
          </Container>
        </div>
        <div className="block3__back">
          <Container>
            <div className="block3__items">{itemsEl1}</div>
          </Container>
        </div>
      </div>
      <div className="block3__2">
        <div className="block3__title">
          <Container>
            Покупка <br />
            разового билета:
          </Container>
        </div>
        <div className="block3__back">
          <Container>
            <div className="block3__items">{itemsEl2}</div>
          </Container>
        </div>
      </div>
    </>
  );
}

export default Block3View;
