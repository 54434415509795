function List(props: any) {
  const { cl, items } = props;
  const itemsEl = items.map((el: any, idx: any) => {
    const other: any = {};
    if (el.w) {
      other.width = el.w;
    }
    return (
      <li key={idx}>
        <img {...other} src={el.icon} alt="" />
        <span>{el.title}</span>
      </li>
    );
  });

  return <ul className={cl}>
    {itemsEl}
  </ul>;
}

export default List;
