import AppLinks from "./applinks";
import Logo from "./elements/logo";
import Container from 'components/elements/container';

function Footer() {
  return (
    <div className="footer" id="footer">
      <Container>
        <div className="footer__logo">
          <Logo />
        </div>
        <AppLinks center />
        <div className="copyright">Все права защищены &copy; BSTR 2021</div>
      </Container>
    </div>
  );
}

export default Footer;
