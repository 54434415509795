import styled from 'styled-components';

export const Humburger = styled.button`
  border: 0;
  margin: 0;
  padding: 0;
  display: none;
  cursor: pointer;
  background: none;
  width: 35px;
  height: 35px;
  border-radius: 3px;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: 991px) {
    display: flex;
    flex-shrink: 0;
  }
`;
export const Close = styled(Humburger)`
  @media screen and (max-width: 991px) {
    display: block;
    position: absolute;
    top: 25px;
    right: 20px;
  }
`;

export const MenuPlace = styled.div`
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: flex-end;
  }

  .menu__logo {
    display: none;
  }

  .menu__logo img {
    max-height: 100%;
    width: auto;
  }

  @media screen and (max-width: 991px) {
    display: none;
    &.active {
      display: block;
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 99999;
      padding: 25px 20px;
      background-color: #fff;
    }
    ul {
      display: block;
      height: auto;
    }
    .menu__logo {
      height: 35px;
      margin: 0 0 70px;
      display: block;
    }
  }
`;
export const MenuItem = styled.li`
  display: block;
  margin: 0;
  padding: 0;
  margin-left: 40px;

  a {
    display: block;
    font-size: 20px;
    line-height: 24px;
    color: #072738;
    text-decoration: none;
    padding: 10px 15px;
    transition: 0.2s all ease-in;
    position: relative;
  }
  a.download {
    padding-left: 42px;
    background: url(/images/download.svg) no-repeat 15px 50%;
  }
  a:hover {
    color: #678798;
  }
  &.menu__item--active a::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    margin-left: -11px;
    width: 22px;
    height: 2px;
    background: #13c671;
    border-radius: 7.5px;
  }
  @media screen and (max-width: 1469px) {
    margin-left: 15px;
    a {
      font-size: 18px;
    }
  }

  @media screen and (max-width: 991px) {
    margin: 0 0 30px;
    &.menu__item--active a {
      color: #099452;
    }
    a {
      padding: 0;
      font-size: 28px;
      line-height: 41px;
    }
    &.menu__item--active a::after,
    .download {
      display: none;
    }
  }
`;
