import Container from 'components/elements/container';
import Block2View from './block2view';
import './block2.css';

function Block2() {
  const items = [
    { icon: '/images/block2/icon1.svg', title: 'Пополнение подорожника' },
    { icon: '/images/block2/icon2.svg', title: 'Разовый QR-код на проезд' },
    { icon: '/images/block2/icon3.svg', title: 'История оплат' },
    { icon: '/images/block2/icon4.svg', title: 'Онлайн-чеки' },
  ];
  return (
    <div className="block block2" id="avd">
      <Container>
        <Block2View
          title="Оплачивайте <br />
          общественный <br />
          транспорт <b>онлайн</b> "
          desc="<p>Пополняйте подорожник онлайн, когда в кассе большая очередь. </p>
          <p>Покупайте разовый билет на наземный транспорт и оплачивайте проезд с помощью QR-кода.</p>"
          items={items}
        />
      </Container>
    </div>
  );
}

export default Block2;
