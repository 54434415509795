import Menu from './menu';
import { HeaderPlace, HeaderFlex, Left, Right, HeaderLogo } from './header.styled';
import Container from 'components/elements/container';

function Header() {
  return (
    <HeaderPlace className="header">
      <Container>
        <HeaderFlex>
          <Left>
            <HeaderLogo className="header__logo" href="/">
              <img src="/images/logo_bstr.svg" alt="BSTR" height="59" />
            </HeaderLogo>
          </Left>
          <Right>
            <Menu />
          </Right>
        </HeaderFlex>
      </Container>
    </HeaderPlace>
  );
}

export default Header;
