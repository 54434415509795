import { Link, useParams } from 'react-router-dom';

import Container from 'components/elements/container';
import { Title, News, NewsItems, NewsItem } from './page-news.styled';
import { news } from './news';
import NewsDetail from './news-detail';

function PageNews() {
  const params = useParams();
  //console.log('🚀 ~ PageNews ~ location', params);
  const { id } = params;
  const isDetail = id && news[id];
  const getItems = () => {
    return Object.keys(news).map((key) => {
      const item = news[key];
      return (
        <NewsItem key={key}>
          <Link to={key}>{item.title}</Link>
        </NewsItem>
      );
    });
  };

  const items = isDetail ? null : getItems();

  return (
    <Container>
      <Title>Новости</Title>
      <News>{isDetail ? <NewsDetail id={id} /> : <NewsItems>{items}</NewsItems>}</News>
    </Container>
  );
}

export default PageNews;
