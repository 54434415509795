import { isMobile } from 'react-device-detect';

export function Link(props: { className?: string; href: string; children: any }) {
  const { children, ...rest } = props;
  const additional: any = {};
  //console.log('🚀 ~ Link ~ isMobile', isMobile);
  if (!isMobile) {
    rest.href = '#footer';
    additional.onClick = (event: any) => {
      event.preventDefault();

      window.slideTo(rest.href);
    };
  }
  return (
    <a {...rest} {...additional}>
      {children}
    </a>
  );
}

export default Link;
