import AppLinks from 'components/applinks';

function Block4View(props: any) {
  const { items } = props;

  const getItem = (idx: number) => {
    const item: any = items[idx];
    const cl = 'block4__item block4__item--' + (idx + 1);
    return (
      <a href={item.link} className={cl}>
        <span className="block4__item-title" dangerouslySetInnerHTML={{ __html: item.title }}></span>
      </a>
    );
  };

  return (
    <div className="block4__items">
      <div className="row">
        {getItem(0)}
        {getItem(1)}
      </div>
      <div className="row">
        {getItem(2)}
        <div className="block4__item--mobile" id="apps">
          <div className="title-small">
            Оплачивайте транспорт с <br />
            <b>BSTR</b> в пару кликов
          </div>
          <AppLinks center />
        </div>
      </div>
    </div>
  );
}

export default Block4View;
