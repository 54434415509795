import AppLinks from 'components/applinks';
import List from 'components/elements/list';
//import { Place } from './container.styled';

function Block1View(props: any) {
  const { title, desc, items, img } = props;
  const image = '/images/block1/' + img;

  return (
    <div className="two-columns">
      <div className="block1__left two-columns__left">
        <h1>
          {title} <b>Петербурга</b>
        </h1>
        <div className="title-gray">{desc}</div>
        <List cl="list2" items={items} />
        <AppLinks />
      </div>
      <div className="block1__right two-columns__right">
        <img src={image} alt="" />
      </div>
    </div>
  );
}

export default Block1View;
